import React, {useState, useEffect} from "react";
import {Field, Form, Formik, useField} from "formik";
import {
    Button,
    ButtonToolbar,
    Container,
    Content,
    ControlLabel,
    DatePicker,
    Footer,
    FormGroup,
    Header,
    InputPicker,
    Navbar,
} from "rsuite";
import logo from "./codenowLogo.svg";

import "./App.css";
import "rsuite/dist/styles/rsuite-default.css";
import axios from "axios";

import {useInterval} from './utils'

function App() {
    const [showForm, setShowForm] = useState(true);
    return (
        <Container style={{height: "100vh"}}>
            <Header>
                <Navbar appearance="inverse">
                    <Navbar.Header>
                        <img src={logo} style={{width: 120, margin: 3, marginRight: 20}} alt="logo"/>
                        <span style={{color: "#FFF", fontSize: "125%"}}>Ticket Reservation</span>
                    </Navbar.Header>
                    <Navbar.Body>
                    </Navbar.Body>
                </Navbar>
            </Header>

            <Content style={{width: 300, margin: 24}}>
                {showForm ? (
                        <>
                            <Reservations/>
                            <button style={{margin: "24px 0", width: "150px"}} onClick={() => setShowForm(false)}>
                                View Reservations
                            </button>
                        </>
                    )
                    : (
                        <>
                            <ReservationsView/>
                            <button style={{margin: "24px 0", width: "150px"}} onClick={() => setShowForm(true)}>
                                Create New Reservation
                            </button>
                        </>
                    )}
            </Content>

            <Footer style={{alignSelf: "center", flexBasis: 0, margin: 12}}>
                Copyright @ CodeNOW, 1616 16th Street, San Francisco, CA 94103 USA
            </Footer>
        </Container>
    );
}

const Reservations = () => {
    return (
        <Formik
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                seatId: "",
                connectionId: "",
                date: new Date(),
            }}
            onSubmit={({date, ...rest}, {setSubmitting}) => {
                let data = {date: date.toISOString(), ...rest};
                console.log("Sending reservation data...", data);

                axios
                    .post(`${window.env.BACKEND_URL}/reservation`, data)
                    .then((response) => {
                        console.log("Response", response);
                        alert("Reservation is being processed");
                    })
                    .catch((error) => {
                        console.error("Error", error);
                        alert("Failed to create reservation");
                    })
                    .finally(() => setSubmitting(false));
            }}
        >
            {({isSubmitting}) => (
                <Form className={"rs-form rs-form-vertical rs-form-fixed-width"}>
                    <h1>Reservation</h1>

                    <FormGroup>
                        <ControlLabel>Date</ControlLabel>
                        <FormikDatePicker name="date"/>
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Connection</ControlLabel>
                        <FormikInputPicker
                            name="connectionId"
                            data={[
                                {value: "IC-879", label: "IC 879 Hamburg"},
                                {value: "ICE-575", label: "ICE 575 Munich"},
                                {value: "Sp-5031", label: "Sp 5031"},
                                {value: "Sp-9301", label: "Sp 9301"},
                            ]}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Seat ID</ControlLabel>
                        <FormikInputPicker
                            name="seatId"
                            data={[
                                {value: "1-10", label: "Coach 1 / Seat 10"},
                                {value: "1-21", label: "Coach 1 / Seat 21"},
                                {value: "1-22", label: "Coach 1 / Seat 22"},
                                {value: "1-23", label: "Coach 1 / Seat 23"},
                                {value: "2-14", label: "Coach 2 / Seat 14"},
                                {value: "2-15", label: "Coach 2 / Seat 15"},
                                {value: "3-7", label: "Coach 3 / Seat 7"},
                            ]}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>First name</ControlLabel>
                        <Field name="firstName" placeholder="Tony" className="rs-input"/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Last name</ControlLabel>
                        <Field name="lastName" placeholder="Smith" className="rs-input"/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Email</ControlLabel>
                        <Field
                            name="email"
                            placeholder="tony.smith@gmail.com"
                            type="email"
                            className="rs-input"
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonToolbar>
                            <Button
                                type="submit"
                                appearance="primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </Button>
                            <Button appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    );
};

const FormikDatePicker = ({name}) => {
    const [field, meta, helpers] = useField(name);
    return (
        <DatePicker
            isoWeek
            oneTap
            style={{width: "100%"}}
            name={name}
            value={field.value}
            onChange={(value) => {
                helpers.setValue(value)
            }}
        />
    );
};

const FormikInputPicker = ({name, data}) => {
    const [field, meta, helpers] = useField(name);

    return (
        <InputPicker
            style={{width: "100%"}}
            name={name}
            value={field.value}
            data={data}
            onChange={(value) => helpers.setValue(value)}
        />
    );
};


const ReservationsView = () => {
    const [reservations, setReservations] = useState([]);
    const [etag, setEtag] = useState("");

    useEffect(() => {
            getList()
    }, [])

    useInterval(() => {
        getList()
    }, 5000);

    const CancelReservation = (id) => {
        console.log("Cancel reservation with id: " + id);
        axios.put(`${window.env.BACKEND_URL}/reservation/cancel/${id}`)
            .then(res => {
                console.log(res);
                setReservations(reservations.map(r =>
                    r.id === id ? {...r, status: "PENDING"} : r
                ))
            })
            .catch((error) => {
                console.error("Error", error);
                alert("Failed to cancel reservation");
            })
    };

    const getList = () => {
        axios
            .get(`${window.env.BACKEND_URL}/reservation`, {headers: { "If-None-Match": etag}})
            .then((response) => {
                console.log("Response", response);
                setReservations(response.data);
                setEtag(response.headers.etag)
            })
            .catch((error) => {
                console.error("Error", error);
                // alert("Failed to get reservation view");
            })
    };

    const renderHeader = () => {
        let headerElement = ['first Name', 'last Name', 'email', 'seat Id', 'connection Id', 'date', 'status', 'action']
        return reservations.length > 0 && headerElement.map((key, index) => {
            return <th className={"App-reservations-view-table"} key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        console.log(reservations);
        return reservations.length > 0 && reservations.map(({
                                                                id,
                                                                firstName,
                                                                lastName,
                                                                email,
                                                                seatId,
                                                                connectionId,
                                                                date,
                                                                status
                                                            }, index) => {
            if (status === "ACTIVE") {
                return (
                    <tr key={index}>
                        <td className={"App-reservations-view-table"}>{firstName}</td>
                        <td className={"App-reservations-view-table"}>{lastName}</td>
                        <td className={"App-reservations-view-table"}>{email}</td>
                        <td className={"App-reservations-view-table"}>{seatId}</td>
                        <td className={"App-reservations-view-table"}>{connectionId}</td>
                        <td className={"App-reservations-view-table"}>{new Date(date).toDateString()}</td>
                        <td className={"App-reservations-view-table"}>{status}</td>
                        <td className={"App-reservations-view-table"}>
                            <button onClick={() => CancelReservation(id)}> Cancel Reservation</button>
                        </td>
                    </tr>
                )
            } else {
                return (
                    <tr key={index}>
                        <td className={"App-reservations-view-table"}>{firstName}</td>
                        <td className={"App-reservations-view-table"}>{lastName}</td>
                        <td className={"App-reservations-view-table"}>{email}</td>
                        <td className={"App-reservations-view-table"}>{seatId}</td>
                        <td className={"App-reservations-view-table"}>{connectionId}</td>
                        <td className={"App-reservations-view-table"}>{new Date(date).toDateString()}</td>
                        <td className={"App-reservations-view-table"}>{status}</td>
                        <td className={"App-reservations-view-table"}/>
                    </tr>
                )
            }
        })
    }

    return (
        <div className={"App-reservations-view"}>
            <h3><nobr>Table of last 50 reservations</nobr></h3>
            <table style={{textAlign: "center"}} className={"App-reservations-view-table"}>
                <thead className={"App-reservations-view-table"}>
                <tr>
                    {renderHeader()}
                </tr>
                </thead>
                <tbody className={"App-reservations-view-table"}>
                {renderBody()}
                </tbody>
            </table>
        </div>
    );
};

export default App;
